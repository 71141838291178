import { Page } from "./Page";
import AppProviders from "./components/AppProviders";
import "./style.css";

/**
 * App Component
 *
 * This component is responsible for
 * - Applying global styles
 * - Orchestrating the app's context providers (global state for hooks)
 * - Rendering the Page component
 */
export function App({ children, pageData, dataLayerContent }) {
  return (
    <AppProviders pageData={pageData}>
      <Page pageData={pageData} dataLayerContent={dataLayerContent}>
        {children}
      </Page>
    </AppProviders>
  );
}

export default App;
