// @ts-check
import { runConsentQueue, setupOneTrust } from "@sciam/shared";

const ONETRUST_URL = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
export const ONETRUST_ID = "40eabc27-ca0b-4e3c-a27e-766767a5f442";

/** @type {import('@sciam/shared').ServerScriptConfig} */
export const server = {
  shim: { id: "onetrust", inline: "OptanonWrapper=()=>{};consentQueue=[]" },
  preload: ONETRUST_URL,
};

/**
 * Set up a promise to represent when OneTrust is ready.
 * Expose the resolve call so we can pass it around.
 * @type {(value: any) => void}
 */
let resolveOneTrustSdkInit;
const oneTrustSdkInit = new Promise((resolve) => {
  resolveOneTrustSdkInit = resolve;
});

/** @type {import('@sciam/shared').ScriptConfig} */
export const client = {
  id: "onetrust",
  src: ONETRUST_URL,
  attrs: {
    "data-domain-script": ONETRUST_ID,
    async: false, // Prioritize loading OneTrust
  },
  onBeforeLoad() {
    // Set up OneTrust event listeners
    setupOneTrust({
      onSdkInit: resolveOneTrustSdkInit,
      onFirstConsentSignal(detail) {
        console.log("[onetrust] First consent signal received", detail);
      },
    });
  },
  onError() {
    console.log("[onetrust] Failed to load OneTrust SDK, running queue with no consent");
    runConsentQueue("", "error");
  },
  async readyWhen() {
    await oneTrustSdkInit;
    console.log("[onetrust] OneTrust SDK ready");
  },
};
