import { usePianoErrorHandler } from "~/features/piano/error-handling";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useUserInfoDataLayerSync } from "~/features/piano/user-info";
import { pushDataLayer } from "~/lib/analytics/datalayer";
import { useTrackInstitutionalAccess } from "~features/institutional/tracking";

function initAds(config) {
  if (config) {
    window.__ads.push(["page", config]);
  }
}

function initDataLayer(content) {
  if (content) {
    pushDataLayer(content, false);
  } else {
    console.warn(`🔥 NO DATALAYER PRESENT`);
  }
}

let initialized = false;

/**
 * Page Component
 *
 * This component runs on every page and initializes the data layer and ads.
 * It has access to all the app's context providers, which means we can use state hooks,
 * namely usePiano, usePageContext, and useUserInfo.
 */
export function Page({ children, pageData, dataLayerContent }) {
  // Initialize data layer on first render
  useEffect(() => {
    if (initialized) return;

    initDataLayer(dataLayerContent);
    initAds(pageData.adsConfig);

    initialized = true;
  }, []);

  // Handle Piano script load errors
  usePianoErrorHandler();

  // Call hook to initialize trackig pixel
  useTrackInstitutionalAccess();

  // Pushes user info to the dataLayer when user info is available
  // Currently only executes once per page load
  useUserInfoDataLayerSync();

  return children;
}

export default Page;
