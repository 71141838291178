import ReactDOM from "react-dom/client";

import { perf } from "@sciam/shared";
import * as Sentry from "@sentry/react";
import setupSentry from "~/features/sentry";
import App from "~core/App";
import AppErrorBoundary from "~core/components/ErrorBoundary";
import { load as loadScripts } from "~core/scripts/index.client";
import { syncViewportHeightProperty } from "~lib/viewport";

async function retry(callback, attempts = 5) {
  for (let index = 1; index <= attempts; index++) {
    try {
      let result = await callback();
      console.debug(`[bundle] loaded on attempt ${index}`);
      return result;
    } catch (e) {
      console.debug(`[bundle] failed, attempt ${index}`, e);

      // Give up and raise the error to Sentry
      if (index === attempts) {
        throw e;
      }

      continue;
    }
  }
}

// app-load-start is inlined to the head in ~core/scripts/etc.js
performance.mark("app-load-end");
const measureHydration = perf("hydrate");
const measureBundleImport = perf("bundle-import");

// Initialize Sentry
const BUNDLE = window.__DATA__.bundle;
// @TODO: Refactor this to use window.__DATA__.initialData.disableAds or something
const disableAds = BUNDLE === "authentication";
const disableTracking = BUNDLE === "authentication";
const disableSentrySetup = false;

if (!disableSentrySetup) setupSentry();

const appElement = document.getElementById("app");
const bundleImport = retry(() => import(`~bundles/${window.__DATA__.bundle}.jsx`));

async function hydrate() {
  // Update `--vph` CSS variable on resize
  syncViewportHeightProperty();

  const bundle = await bundleImport;
  measureBundleImport();

  // Remove loading attribute when in Vite Dev Server
  if (__CMD__ === "serve") appElement.removeAttribute("data-vite-loading");

  const Component = bundle.default;
  const initialData = window.__DATA__.initialData || {};

  // @TODO: This should be root-level __DATA__ but for now we'll use initialData
  // const dataLayerContent = window.__DATA__.dataLayerContent || {};
  const dataLayerContent = initialData.dataLayerContent || {};

  const measureRender = perf("render");

  // @TODO: Wrap Component in React.lazy/React.Suspense

  ReactDOM.hydrateRoot(
    appElement,
    <AppErrorBoundary>
      <App pageData={initialData} dataLayerContent={dataLayerContent}>
        <Component {...initialData?.props} />
      </App>
    </AppErrorBoundary>,
  );

  measureRender();
  measureHydration();

  // send an event to the browser to indicate hydration is complete
  window.__hydrated__ = true;
  window.dispatchEvent(new Event("hydration"));

  // wait for browser idle time to report performance metrics
  await loaded;

  performance.measure("app-load", "app-load-start", "app-load-end");

  // Report performance metrics to Sentry when the browser is idle
  if (window.requestIdleCallback) {
    window.requestIdleCallback(performanceReport, { timeout: 2000 });
  } else {
    // Fall back to setTimeout because Safari
    setTimeout(performanceReport, 2000);
  }
}

function performanceReport() {
  const perfEntries = performance.getEntriesByType("measure");

  // Report performance metrics to Sentry
  // https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/performance-metrics/
  perfEntries.forEach((m) => Sentry?.setMeasurement?.(m.name, m.duration, "ms"));

  // Log performance metrics to the console
  console.debug(
    perfEntries
      .map((m) => `${m.name}: ${Math.floor(m.duration * 100) / 100}ms`)
      .sort()
      .join("\n") + `, to idle: ${Math.floor(performance.now() * 100) / 100}ms`,
  );
}

console.log("[app] entry-bundle.jsx");

// Load scripts in parallel with hydration
// Some scripts will wait for hydration to complete before executing
const loaded = loadScripts({ ads: !disableAds, tracking: !disableTracking }).catch((error) => {
  // This will only happen when an unhandled exception occurs in ~core/scripts/index.client.js
  console.error("[app] loadScripts() error", error);
});

// Hydrate the app
hydrate().catch((error) => {
  // Most likely causes:
  // - The bundle import fails (e.g. 404)
  // - The app throws an error during first render
  // - Unsupported browser or extension behavior
  // - Solar flares, armageddon
  console.error("[app] hydrate() error", error);
  window.__hydrated__ = "error";
  window.dispatchEvent(new Event("hydration-error", { error }));
  performanceReport();
  Sentry?.captureException?.(error);
});
