import { linkConsentStateToPiano, pianoScriptUrl } from "@sciam/piano";
import { setupPianoQueue } from "~/features/piano";

export const server = {
  shim: { id: "piano", inline: "tp=[];pdl={requireConsent:'v2'}" },

  // We don't know for sure that this will be the Piano script,
  // but it's a good guess for network performance in prod
  // @NB: Removed for auth Bug testing
  // preload: "https://experience.tinypass.com/xbuilder/experience/load?aid=FU52w9tupu",

  // Both Piano environments end up loading this script
  preloadLazy: "https://cdn.tp.scientificamerican.com/api/tinypass.min.js",

  // Piano also loads these scripts. We don't need them for init but this
  // should shave off time in DNS/TLS resolution.
  preconnect: [
    "https://cdn.cxense.com/cx.cce.js",
    // "https://cdn.cxense.com/cx.js"
  ],
};

/** @type {import('@sciam/shared').ScriptConfig} */
export const client = {
  id: "piano",
  src: pianoScriptUrl,
  onBeforeLoad() {
    console.log("[piano] running tp setup prior to script load");
    setupPianoQueue();
  },
  onLoad() {
    console.log("[piano] loaded");
  },
  readyWhen: pianoReady,
  onError() {
    console.log("[piano] error");
    window._pianoError = true;
  },
};

function pianoReady() {
  return new Promise((resolveReady) => {
    console.log("[piano] waiting for ready");
    // @ts-ignore
    window.tp.push([
      "init",
      () =>
        linkConsentStateToPiano(() => {
          tp.experience.init();

          console.log("[piano] ready");
          resolveReady();
        }),
    ]);
  });
}
