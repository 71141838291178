const GTM_ID = "GTM-5FLM662";
const GTM_URL = "https://www.googletagmanager.com/gtm.js?id=";

// Inject extra functionality into the GTM snippet
const GTM_EXTRAS =
  // evt() will dispatch a custom event to the document
  "var evt=function(e){return function(){d.dispatchEvent(new CustomEvent(e,{bubbles: true}))}};" +
  // trigger events on load and error
  "j.onload=evt('gtm-load');j.onerror=evt('gtm-error');";

const GTM_SNIPPET = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='${GTM_URL}'+i+dl;f.parentNode.insertBefore(j,f);${GTM_EXTRAS}})(window,document,'script','dataLayer','${GTM_ID}');`;

/** @type {import('@sciam/shared').ServerScriptConfig} */
export const server = {
  preload: GTM_URL + GTM_ID,
  shim: { id: "gtm-dl", inline: "window.dataLayer=[];" },
};

/** @type {import('@sciam/shared').ScriptConfig} */
export const client = {
  id: "gtm",
  inline: GTM_SNIPPET,
  onLoad() {
    console.log("[gtm] injected");
  },
  onError() {
    console.error("[gtm] error");
  },
  readyWhen: () =>
    new Promise((resolve, reject) => {
      document.addEventListener("gtm-load", () => {
        console.log("[gtm] loaded");
        resolve();
      });
      document.addEventListener("gtm-error", () => {
        console.error("[gtm] error loading");
        reject();
      });
    }),
};
