// Load IDP script
// It redirects users with Institutional Access away from
// proxies etc.

// @TODO when Mura is gone, make this a proper JS module rather
// than static loaded like ads.

// Pointing to Hopper version instead of static toolkit version
const src = "/static/standalone/idp.js";

/** @type {import('@sciam/shared').ServerScriptConfig} */
export const server = {
  preload: src,
  preconnect: "https://idp_shim.sciam.com",
};

/** @type {import('@sciam/shared').ScriptConfig} */
export const client = {
  id: "idp-js",
  src: src,
  onLoad() {
    console.log("[idp.js] injected");
  },
};
