// @ts-check
const CHARTBEAT_UID = 21673;
const CHARTBEAT_URL = "//static.chartbeat.com/js/chartbeat.js";
const CHARTBEAT_MAB_URL = "//static.chartbeat.com/js/chartbeat_mab.js";

/**
 * @see https://docs.chartbeat.com/cbp/tracking/standard-websites/configuration-variables
 */
export function configureChartbeat() {
  /** 1. Precompute config values from our dataLayer */
  const content = window.__DATA__.initialData.dataLayerContent?.content || {};

  // Authors may be a string or an array of strings depending on the page
  // Either way we want to join them into a comma-separated string
  let authors = (content.authors || []).join?.(",") || content.authors || "";

  // Editors can only be an array. For chartbeat's purpose, add them to the Author list.
  if (content.editors && content.editors.length) {
    let editors = content.editors.join(",");
    authors += `, ${editors}`;
  }

  // Create a superset of all possible section tokens
  const allSections = [
    content.type, // article, opinion, partner article, news, etc.
    content.primaryCategory,
    content.subCategory,
    // ...content.categories.split(",").map((c) => c.trim()),
    content.isOpinion && "opinion",
    content.podcastSeries && "podcast",
    content.podcastSeries,
    content.collectionName,
  ];

  // Flatten, filter out empty items, dedupe, and join into a comma-separated string
  const dedupedSections = Array.from(new Set(allSections.flat().filter(Boolean))).join(",");

  /** 2. Chartbeat configuration */
  const config = window._sf_async_config;
  config.uid = CHARTBEAT_UID;

  // Chartbeat wants the domain without the www prefix unless it's a real subdomain
  config.domain = window.location.hostname.replace(/^www\./, "");

  // Chartbeat suggest using canonical URL for path but those are sometimes set to other domains (e.g. syndicated content)
  // Our path routing is deterministic so we can just use the current path
  config.path = window.location.pathname;

  // These are overridden by the above and disabled by default, but we'll set them here for clarity
  config.useCanonical = false;
  config.useCanonicalDomain = false;

  // When this isn't present, Chartbeat tries to guess the title on its own
  // which leads to a lot of "Scientific American" titles in the dashboard
  // This passes the value from the dataLayer if it's present
  // otherwise it'll strip the pipe and everything after it
  config.title = content.title || document.title.replace(/ \|.*/, "");

  // These must always be strings
  config.authors = authors;
  config.sections = dedupedSections;

  // This prevents some weird behaviors caused by chartbeat_mab.js
  config.flickerControl = false;
}

/** @type {import('@sciam/shared').ServerScriptConfig}*/
export const server = {
  shim: { id: "chartbeat", inline: "_sf_async_config={};_cbq=[]" },
};

/** @type {import('@sciam/shared').ScriptConfig} */
export const client = {
  id: "chartbeat",
  src: CHARTBEAT_URL,
  attrs: {
    async: true,
    type: "text/javascript",
  },
  onBeforeLoad() {
    configureChartbeat();
  },
  onLoad() {
    console.log("[chartbeat] loaded");
  },
  onError() {
    console.log("[chartbeat] error");
  },
};

/** @type {import('@sciam/shared').ScriptConfig} */
export const clientMab = {
  id: "chartbeatMab",
  src: CHARTBEAT_MAB_URL,
  attrs: { async: true },
  onLoad() {
    console.log("[chartbeatMab] loaded");
  },
  onError() {
    console.log("[chartbeatMab] error");
  },
};
