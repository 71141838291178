import { useEffect } from "react";

import { usePiano } from "@sciam/piano/react";

export function usePianoErrorHandler() {
  const { setLoadError } = usePiano();

  useEffect(() => {
    // Variable set in ~core/scripts/piano.js
    if (window._pianoError) {
      setLoadError();
    }

    // All script in our frontend dispatch custom events:
    // - "script-load" and "script-ready" for successful loads
    // - "script-error" for errors during either load or initialization
    window.addEventListener("script-error", (event) => {
      if (event.detail?.id === "piano") setLoadError();
    });
  }, []);
}

export default usePianoErrorHandler;
