const sciAdsUrl =
  import.meta.env.PUBLIC_SCIADS || "https://www.scientificamerican.com/sciads/sciads.js";

/** @type {import('@sciam/shared').ServerScriptConfig} */
export const server = {
  // @TODO
  preload: sciAdsUrl,
  shim: { id: "sciads", inline: "window.__ads=[]" },
};

/** @type {import('@sciam/shared').ScriptConfig} */
export const client = {
  id: "sciads",
  src: sciAdsUrl,
  onLoad() {
    console.log("[sciads] injected");
  },
};
