import { useUserAccess } from "@sciam/piano/react";
import { useEffect } from "react";
import { useAuth } from "~/features/auth";
import { pushDataLayer } from "~/lib/analytics/datalayer";

let pushedUserInfo = false;

// @TODO: We really need to stop copy-pasting this function and point them all to @sciam/shared
function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function readJsonCookie(name) {
  try {
    return JSON.parse(readCookie(name));
  } catch (e) {
    return null;
  }
}

const getBpid = () =>
  readCookie("idp_magic") ||
  readCookie("ezproxy_bpid") ||
  readJsonCookie("idp_details")?.BPID ||
  null;

/**
 * React hook to push user info to the dataLayer when user info is available.
 *
 * It serves the same purpose as `pushUserInfoToDatalayer()` in `piano-mura.js`
 *
 * See also `~core/Page.jsx`
 */
export function useUserInfoDataLayerSync() {
  const { isLoggedIn, user, isLoading } = useAuth();
  const { hasSub, hasAccess, hasUnlimitedAccess, hasIdpAccess, primaryResource } = useUserAccess();

  useEffect(() => {
    if (
      // Don't do anything if Piano is still loading.
      isLoading ||
      // `hasSub` is undefined until we know the user's access status.
      typeof hasSub === "undefined"
    )
      return;

    const BPID = hasIdpAccess ? getBpid() : undefined;

    // Update Sentry user context when user info changes
    window?.__SENTRY__?.hub?.setUser?.({
      ip_address: "{{auto}}",
      id: user?.uid || undefined,
      email: user?.email || undefined,
      bpid: BPID || undefined,
      has_sub: hasSub,
      has_idp: hasIdpAccess,
      has_access: hasAccess,
      has_unlimited_access: hasUnlimitedAccess,
    });

    // Push latest user state to Chartbeat
    // https://docs.chartbeat.com/cbp/tracking/standard-websites/subscriber-engagement
    const cbUserType = hasSub ? "paid" : isLoggedIn ? "lgdin" : "anon";
    window._cbq?.push(["_acct", cbUserType]);

    // Don't push to dataLayer more than once
    if (pushedUserInfo) return;

    const dataLayerObj = {
      event: "userInfo",
      user: {
        // User
        userId: user?.uid || "",
        isLoggedIn: !!isLoggedIn,
        isRegistered: !!isLoggedIn,

        // Subscription
        isSubscriber: !!hasSub,
        subscriptionName: primaryResource?.rid || undefined,
        subscriptionType: primaryResource?.name || undefined,

        // IDP
        isSiteLicenseCustomer: !!hasIdpAccess,
        siteLicenseBPID: BPID || undefined,
      },
    };

    pushDataLayer(dataLayerObj);
    pushedUserInfo = true;
  }, [isLoading, hasSub, hasIdpAccess]);
}
