import { ErrorBoundary, withProfiler } from "@sentry/react";
import { useEffect } from "react";

function ErrorFallback({ error, resetErrorBoundary }) {
  useEffect(() => {
    // If we're in development, display the error in Vite's error overlay
    if (__CMD__ === "serve") document.getElementById("app").removeAttribute("data-vite-loading");

    const ErrorOverlay = customElements.get("vite-error-overlay");
    if (ErrorOverlay && !document.querySelector("vite-error-overlay")) {
      const viteAssetPath = window.location.origin + "/static";

      // @FIXME: This is a hack to make the error overlay work without resolving Vite asset paths.
      // Not ideal, but it's helpful enough for now.
      error.stack = error.stack
        .replaceAll(viteAssetPath, "") // https://local-www.scientificamerican.com/static/*
        .replaceAll(/\?\w=\w+:/g, ":"); // Cache-busting query params

      const overlay = new ErrorOverlay(error);
      document.body.appendChild(overlay);

      return () => {
        if (document.body.contains(overlay)) document.body.removeChild(overlay);
      };
    }
  }, []);

  return (
    <div>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre style={{ color: "red" }}>{error.stack}</pre>
      <pre>{error.toString()}</pre>

      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

function AppErrorBoundary({ children }) {
  return (
    <ErrorBoundary FallbackComponent={__DEV__ ? ErrorFallback : undefined}>
      {children}
    </ErrorBoundary>
  );
}

export default withProfiler(AppErrorBoundary);
