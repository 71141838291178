/**
 * @typedef {{ ts: number, [key: string]: any }} EventData
 * @typedef {{ events: EventData[], state?: any }} SessionLog
 */

/** Use `SessionStorage` API to maintain a timestamped log of events */
export function createSessionLog(key = "session-log", max_length = 100) {
  const storage = window.sessionStorage;
  const logStr = storage.getItem(key) || `{"events":[]}`;
  /** @type {SessionLog} */
  let log = { events: [] };
  try {
    log = /** @type {SessionLog} */ (JSON.parse(logStr));
  } catch {}

  return {
    log,

    /** @type {(eventData?: EventData, state?: any) => void} */
    add(eventData = {}, state) {
      // Update event log
      log?.events?.push({
        ts: Date.now(),
        ...eventData,
      });
      if (log?.events?.length > max_length) log.events.shift?.();

      // Update latest state value
      if (state !== undefined) log.state = state;

      storage.setItem(key, JSON.stringify(log));
    },
    clear() {
      storage.removeItem(key);
    },
  };
}

export default createSessionLog;
