import { createContext, useContext, useState } from "react";

const initialState = {
  info: {},
  instance: null,
  ready: false,
  currentTime: 0,
  duration: 0,
  muted: false,
  paused: true,
  volume: 1,
  loop: false,
}

export const MediaStateContext = createContext([ initialState, () => {} ]);

export function ProvideMediaState({ children, initial = initialState }) {
  const [state, updateState] = useProvideMediaState(initial);
  return (
    <MediaStateContext.Provider value={[state, updateState]}>{children}</MediaStateContext.Provider>
  );
}

export const useMediaState = () => {
  return useContext(MediaStateContext);
};

function useProvideMediaState(initial) {
  const [mediaState, setMediaState] = useState(initial);

  function updateMediaState(newState) {
    setMediaState((state) => ({ ...state, ...newState }));
  }

  return [mediaState, updateMediaState];
}
